exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-slug-js": () => import("./../../../src/pages/blog/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-blog-mdx-slug-js" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contactUs.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-fulfilment-refund-policy-jsx": () => import("./../../../src/pages/fulfilment-refund-policy.jsx" /* webpackChunkName: "component---src-pages-fulfilment-refund-policy-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/Home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-summer-camp-jsx": () => import("./../../../src/pages/SummerCamp.jsx" /* webpackChunkName: "component---src-pages-summer-camp-jsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/TermsAndConditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

